import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@app/config/store';
import { countries, msicList, stateList } from '@app/constant/codes';
import { InputTextDirective } from '@app/directives/input-text.directive';
import {
  commaSeparatedEmailMaxLengthValidator,
  counterPartyTin,
  email,
  noSpacesValidator,
  SSTNumber,
  taxNumber,
  tinNumber,
} from '@app/dto/common.dto';
import { fieldErrors } from '@app/dto/taxpayer.dto';
import { MastersService } from '@app/services/masters.service';
import { Tin, TinsService } from '@app/services/tins.service';
import {
  SearchSelect,
  SearchSelectComponent,
} from '@app/shared/search-select/search-select.component';
import { Vendor, VendorLineItem } from '@app/types/taxpayer.type';
import { distinctUntilChanged } from 'rxjs';

interface ShippingData {
  srAddrLine0: string | null;
  srAddrLine1: string | null;
  srAddrLine2: string | null;
  srCityName: string | null;
  srCountry: string | null;
  srName: string | null;
  srPostalZone: string | null;
  srROrIdType: string | null;
  srRonOrIdNoOrPassportNo: string | null;
  srState: string | null;
  srTin: string | null;
  current: boolean;
}
@Component({
  selector: 'app-master-customer-create',
  standalone: true,
  imports: [ReactiveFormsModule, SearchSelectComponent, InputTextDirective, DatePipe],
  templateUrl: './master-customer-create.component.html',
  styleUrl: './master-customer-create.component.css',
})
export class MasterCustomerCreateComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() doneEvent = new EventEmitter<any>();
  @Input() vendor!: Vendor | null;
  itemsLength!: number;
  addMasterCustomerForm: FormGroup = new FormGroup({
    issuerTin: new FormControl('', [Validators.maxLength(14), tinNumber, Validators.required]),
    category: new FormControl('', Validators.required),
    ctpyTin: new FormControl('', [Validators.maxLength(14), counterPartyTin, Validators.required]),
    ctpyName: new FormControl('', [
      Validators.maxLength(300),
      noSpacesValidator(),
      Validators.required,
    ]),
    ctpyIdType: new FormControl('', [Validators.maxLength(12), Validators.required]),
    ctpyIdNo: new FormControl('', [Validators.required]),
    lineItems: new FormArray([]),
  });
  categoryOptions: string[] = ['Vendor', 'Customer'];
  counterPartyIDType: string[] = ['BRN', 'NRIC', 'Passport', 'ARMY'];

  formErrors = fieldErrors;
  stateList = stateList;
  shippingStateList = stateList;
  countryData = countries;
  msicList = msicList.map((e) => ({
    label: `${e.Code}: ${e.Description}`,
    value: e.Code,
    checked: false,
  }));
  categoryValue: string = '';
  vendorDetails!: Vendor;
  isVendor = false;
  tinsList: Tin[] = [];
  shippingLists: ShippingData[] = [];

  isUpdateVendorRoute: boolean = false;

  lineItemsBackupValidators: Record<string, ValidatorFn[]> = {};

  constructor(
    private fb: FormBuilder,
    private masterService: MastersService,
    private tinService: TinsService,
    private cdrf: ChangeDetectorRef,
    private router: ActivatedRoute,
    private route: Router,
    protected store: Store,
  ) {}

  ngOnInit() {
    if (this.router.snapshot.url[0]?.path === 'update-vendor') {
      this.isUpdateVendorRoute = true;
      this.router.queryParams.subscribe((param) => {
        if (param['vendorId']) {
          const resp = {
            token: param['token'],
            userName: '',
            userEmail: '',
            isMfaRegistered: false,
          };
          this.store.setTaxpayer(resp);
          this.getVendorData(param['vendorId']);
        }
      });
    } else {
      this.isUpdateVendorRoute = false;
    }

    this.getTins();

    this.autoFillCustomerVendorForm();

    this.addMasterCustomerForm
      ?.get('ctpyIdNo')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe(() => {
        if (this.addMasterCustomerForm.get('ctpyIdNo')?.value?.startsWith('NA')) {
          this.addMasterCustomerForm.get('ctpyIdNo')?.setValue('NA');
          return;
        }

        this.validateIdentificationNumber(this.addMasterCustomerForm, 'ctpyIdType', 'ctpyIdNo');
      });

    this.addMasterCustomerForm?.get('ctpyIdType')?.valueChanges.subscribe(() => {
      this.validateIdentificationNumber(this.addMasterCustomerForm, 'ctpyIdType', 'ctpyIdNo');
    });
    this.cdrf.detectChanges();
  }

  getVendorData(vendorId: number) {
    this.masterService.getVendorById({ ids: [vendorId] }).subscribe((res) => {
      if (res.vendorDetails.length > 0) {
        this.vendor = res.vendorDetails[0];
        this.autoFillCustomerVendorForm();
      }
    });
  }

  generateShippingData(item: VendorLineItem) {
    const shippingData: ShippingData = {
      srAddrLine0: item.srAddrLine0,
      srAddrLine1: item.srAddrLine1,
      srAddrLine2: item.srAddrLine2,
      srCityName: item.srCityName,
      srCountry: item.srCountry,
      srName: item.srName,
      srPostalZone: item.srPostalZone,
      srROrIdType: item.srROrIdType,
      srRonOrIdNoOrPassportNo: item.srRonOrIdNoOrPassportNo,
      srState: item.srState,
      srTin: item.srTin,
      current: false,
    };
    this.shippingLists.push(shippingData);
    this.shippingLists.forEach((shipping) => {
      if (this.isShippingDataValid(shipping)) {
        shipping.current = true;
      }
    });
  }

  autoFillCustomerVendorForm() {
    if (this.vendor) {
      this.categoryValue = this.vendor.category;
      this.isVendor = this.vendor.category === 'Vendor';
      Object.keys(this.vendor).forEach((key) => {
        const vendorValue = this.vendor?.[key] != null ? this.vendor[key] : null;
        if (key === 'lineItems' && Array.isArray(vendorValue)) {
          vendorValue.forEach((item) => {
            this.addLineItems(item);
          });
        }
      });
      this.addMasterCustomerForm.patchValue(this.vendor);
    }
  }

  isShippingDataValid(shippingData: ShippingData) {
    return Object.values(shippingData).some(
      (value) => typeof value !== 'boolean' && value !== null && value !== '',
    );
  }

  getTins(refresh = false) {
    this.tinService.getTins(refresh).subscribe((response) => {
      this.tinsList = response;
    });
  }

  addLineItems(item?: VendorLineItem) {
    const items = this.addMasterCustomerForm.get('lineItems') as FormArray;
    const collapseIndex: number = items.value.length - 1;
    const controls = (items as FormArray).controls;
    if (controls[collapseIndex]) {
      controls[collapseIndex].value.isCollapse = false;
    }

    if (item) {
      items.push(this.createLineItemsField(item));
      this.generateShippingData(item);
    } else {
      items.push(this.createLineItemsField());
      this.generateShippingData(this.createLineItemsField().value);
    }

    // Subscribe to value changes for dynamic validation
    const lastIndex = items.length - 1;
    const lastGroup = items.at(lastIndex) as FormGroup;
    lastGroup.get('srROrIdType')?.valueChanges.subscribe(() => {
      this.validateIdentificationNumber(lastGroup, 'srROrIdType', 'srRonOrIdNoOrPassportNo');
    });

    lastGroup
      .get('srRonOrIdNoOrPassportNo')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe(() => {
        if (lastGroup.get('srRonOrIdNoOrPassportNo')?.value?.startsWith('NA')) {
          lastGroup.get('srRonOrIdNoOrPassportNo')?.setValue('NA');
          return;
        }
        this.validateIdentificationNumber(lastGroup, 'srROrIdType', 'srRonOrIdNoOrPassportNo');
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createLineItemsField(item: any = {}): FormGroup {
    const group = this.fb.group({
      ctpyTtrNo: [item.ctpyTtrNo || '', [taxNumber, noSpacesValidator()]],
      uniqueIdentifier: [
        item.uniqueIdentifier || '',
        [noSpacesValidator(), Validators.maxLength(100)],
      ],
      ctpySstrNo: [item.ctpySstrNo || '', [SSTNumber, noSpacesValidator()]],
      ctpyMsic: [item.ctpyMsic || '', []],
      ctpyActivityDesc: [item.ctpyActivityDesc || ''],
      ctpyAddrLine0: [item.ctpyAddrLine0 || '', [noSpacesValidator(), Validators.maxLength(150)]],
      ctpyAddrLine1: [item.ctpyAddrLine1 || '', [noSpacesValidator(), Validators.maxLength(150)]],
      ctpyAddrLine2: [item.ctpyAddrLine2 || '', [noSpacesValidator(), Validators.maxLength(150)]],
      ctpyPostalZone: [item.ctpyPostalZone || '', [noSpacesValidator(), Validators.maxLength(50)]],
      ctpyCityName: [item.ctpyCityName || '', [noSpacesValidator(), Validators.maxLength(50)]],
      ctpyState: [item.ctpyState || ''],
      ctpyCountry: [item.ctpyCountry || ''],
      ctpyEmail: [item.ctpyEmail || '', [email, Validators.maxLength(320)]],
      toField: [item.toField || '', commaSeparatedEmailMaxLengthValidator(320)],
      cc: [item.cc || '', commaSeparatedEmailMaxLengthValidator(320)],
      ctpyContactNo: [
        item.ctpyContactNo || '',
        [noSpacesValidator(), Validators.maxLength(20), Validators.required],
      ],
      mobileNumber: [item.mobileNumber || '', [noSpacesValidator(), Validators.maxLength(20)]],
      srName: [item.srName || '', [noSpacesValidator(), Validators.maxLength(300)]],
      srTin: [item.srTin || ''],
      srROrIdType: [item.srROrIdType || ''],
      srRonOrIdNoOrPassportNo: [item.srRonOrIdNoOrPassportNo || ''],
      srAddrLine0: [item.srAddrLine0 || '', [noSpacesValidator(), Validators.maxLength(150)]],
      srAddrLine1: [item.srAddrLine1 || '', [noSpacesValidator(), Validators.maxLength(150)]],
      srAddrLine2: [item.srAddrLine2 || '', [noSpacesValidator(), Validators.maxLength(150)]],
      srPostalZone: [item.srPostalZone || '', [noSpacesValidator(), Validators.maxLength(50)]],
      srCityName: [item.srCityName || '', [noSpacesValidator(), Validators.maxLength(50)]],
      srState: [item.srState || ''],
      srCountry: [item.srCountry || ''],
      isCollapse: [item.isCollapse !== undefined ? item.isCollapse : true],
    });

    Object.keys(group.controls).forEach((key) => {
      const control = group.get(key);
      this.lineItemsBackupValidators[key] = control?.validator ? [control.validator] : [];
    });

    return group;
  }

  onCategoryChange(val: string): void {
    const issuerTinValue = this.addMasterCustomerForm.controls.issuerTin.value || '';
    this.addMasterCustomerForm.reset();
    (this.addMasterCustomerForm.controls['lineItems'] as FormArray).clear();
    this.addMasterCustomerForm.patchValue({
      category: val,
      issuerTin: issuerTinValue,
    });
    this.categoryValue = val;
    const miscControl = this.addMasterCustomerForm.get('misc');
    miscControl?.clearValidators();
    if (val === 'Vendor') {
      miscControl?.setValidators([Validators.required, Validators.maxLength(5)]);
    } else {
      miscControl?.setValidators(Validators.maxLength(5));
    }
    miscControl?.updateValueAndValidity();
    this.onCounterPartyNumberChange();
  }

  getItemsControls(): AbstractControl[] | null {
    const items = this.addMasterCustomerForm.get('lineItems');
    this.itemsLength = items?.value.length;
    return items ? (items as FormArray).controls : null;
  }

  toggleCollapse(index: number) {
    const items = this.addMasterCustomerForm.get('lineItems') as FormArray;
    const toggleItem = items.at(index).value;
    const updatedItem = { ...toggleItem, isCollapse: !toggleItem.isCollapse };
    items.at(index).patchValue(updatedItem);
  }

  addShipping(index: number) {
    this.shippingLists[index].current = true;
  }

  removeShipping(index: number) {
    this.shippingLists[index].current = false;
    const itemsArray = this.addMasterCustomerForm.get('lineItems') as FormArray;
    const itemFormGroup = itemsArray.controls[index];
    itemFormGroup.get('srName')?.reset();
    itemFormGroup.get('srTin')?.reset();
    itemFormGroup.get('srROrIdType')?.reset();
    itemFormGroup.get('srRonOrIdNoOrPassportNo')?.reset();
    itemFormGroup.get('srAddrLine0')?.reset();
    itemFormGroup.get('srAddrLine1')?.reset();
    itemFormGroup.get('srAddrLine2')?.reset();
    itemFormGroup.get('srPostalZone')?.reset();
    itemFormGroup.get('srCityName')?.reset();
    itemFormGroup.get('srState')?.reset();
    itemFormGroup.get('srCountry')?.reset();
  }

  getMsicValue(msic: string | null) {
    if (!msic) return '';

    const code = this.msicList.filter((item) => {
      return item.value === msic;
    })[0];

    return code?.label ?? '';
  }

  setMsicValue(code: SearchSelect[] | string) {
    const description = this.msicList
      .filter((item) => {
        return item.value === (code as unknown as string);
      })[0]
      .label.split(':')[1];

    const formArray = this.addMasterCustomerForm.get('lineItems') as FormArray;
    formArray.controls.forEach((control) => {
      control.get('ctpyActivityDesc')?.patchValue(description);
      control.get('ctpyMsic')?.patchValue(code);
    });
  }

  removeItem(index: number) {
    const items = this.addMasterCustomerForm.get('lineItems') as FormArray;
    items.removeAt(index);
    this.shippingLists.splice(index, 1);
    const collapseIndex = items.length - 1;
    if (collapseIndex >= 0) {
      (items.at(collapseIndex) as FormGroup).get('isCollapse')?.patchValue(true);
    }
  }

  checkedMSIC(index: number) {
    const formArray = this.addMasterCustomerForm.get('lineItems') as FormArray;

    const ctpyMsicValue = formArray.at(index)?.get('ctpyMsic')?.value;

    const ctpyMsicArray = ctpyMsicValue.split(',');
    const filteredMsicList = this.msicList.filter((item) => ctpyMsicArray.includes(item.value));
    return filteredMsicList;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCountryChange(event: any) {
    const countryCode = event.target.value;

    this.stateList =
      countryCode !== 'MYS' ? this.stateList.filter((map) => map.Code === '17') : stateList;
  }

  lineItemMsic(index: number) {
    const lineItem = this.addMasterCustomerForm.get('lineItems')?.get(index.toString())?.value;
    const codes = lineItem.ctpyMsic?.split(',') || [];
    return this.msicList.map((msic) => ({
      ...msic,
      checked: codes.includes(msic.value),
    }));
  }

  restoreLineItemValidators() {
    const lineItems = this.addMasterCustomerForm.get('lineItems') as FormArray;
    const requiredItems = [
      'uniqueIdentifier',
      'ctpyAddrLine0',
      'ctpyPostalZone',
      'ctpyCityName',
      'ctpySstrNo',
    ];
    const requiredIfVendor = ['ctpyTtrNo', 'ctpyActivityDesc'];

    lineItems.controls.forEach((lineItem) => {
      const lineItemGroup = lineItem as FormGroup;
      Object.keys(lineItemGroup.controls).forEach((controlName) => {
        const control = lineItemGroup.get(controlName);
        if (control) {
          if (this.lineItemsBackupValidators[controlName]) {
            control.setValidators(this.lineItemsBackupValidators[controlName]);

            if (requiredIfVendor.includes(controlName) && this.isVendor) {
              control.addValidators(Validators.required);
              if (!control.value) {
                control.reset();
              }
            }
            if (requiredItems.includes(controlName)) {
              control.addValidators(Validators.required);
              if (!control.value) {
                control.reset();
              }
            }
            control.updateValueAndValidity();
          }
        }
      });
    });
  }

  addUpdateVendor(status: 'ACTIVE' | 'DRAFT') {
    const formValue = this.addMasterCustomerForm.getRawValue();
    if (status === 'DRAFT') {
      const controlsToClears = ['ctpyTin', 'ctpyName', 'ctpyIdType', 'ctpyIdNo', 'issuerTin'];
      controlsToClears.forEach((control) => {
        if (control === 'ctpyIdNo') {
          this.addMasterCustomerForm.controls[control].setErrors(null);
          this.addMasterCustomerForm.controls[control].updateValueAndValidity();
        }
        this.addMasterCustomerForm.controls[control].clearValidators();
        this.addMasterCustomerForm.controls[control].updateValueAndValidity();
      });

      this.getItemsControls()?.forEach((control) => {
        const formGroupControl = (control as FormGroup).controls;
        Object.keys(formGroupControl).forEach((key) => {
          if (formGroupControl && !formGroupControl[key].value) {
            formGroupControl[key].clearValidators();
            formGroupControl[key].updateValueAndValidity();
          }
        });
      });
    }

    if (this.addMasterCustomerForm.valid) {
      const lineItems = this.addMasterCustomerForm.value.lineItems.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item: { [x: string]: any; isCollapse: boolean }) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const lineItems: { [x: string]: any } = {};
          Object.keys(item).forEach((key) => {
            if (key !== 'isCollapse') {
              lineItems[key] = item[key];
            }
          });
          return lineItems;
        },
      );

      const payload = {
        issuerTin: formValue.issuerTin,
        category: formValue.category,
        ctpyTin: formValue.ctpyTin,
        ctpyName: formValue.ctpyName,
        ctpyIdType: formValue.ctpyIdType,
        ctpyIdNo: formValue.ctpyIdNo,
        lineItems: lineItems,
        status: status,
      };

      if (this.vendor) {
        const vendorId = this.vendor.vendorId;
        this.masterService.updateVendor(payload, vendorId).subscribe((res) => {
          if (res) {
            this.doneEvent.emit(payload);
            if (status === 'DRAFT' && this.isUpdateVendorRoute) {
              const controlsToValidate = [
                {
                  control: 'ctpyTin',
                  validators: [Validators.maxLength(14), counterPartyTin, Validators.required],
                },
                {
                  control: 'ctpyName',
                  validators: [Validators.maxLength(300), noSpacesValidator(), Validators.required],
                },
                {
                  control: 'ctpyIdType',
                  validators: [Validators.maxLength(12), Validators.required],
                },
                { control: 'ctpyIdNo', validators: [Validators.required] },
                {
                  control: 'issuerTin',
                  validators: [Validators.maxLength(14), tinNumber, Validators.required],
                },
              ];
              controlsToValidate.forEach(({ control, validators }) => {
                const formControl = this.addMasterCustomerForm.controls[control];

                formControl.addValidators(validators);
                formControl.updateValueAndValidity();

                if (formControl.invalid) {
                  formControl.reset();
                }
              });
              this.restoreLineItemValidators();
              const issuerTinValue = this.addMasterCustomerForm.controls.issuerTin.value || '';
              this.addMasterCustomerForm.patchValue({
                issuerTin: issuerTinValue,
              });
            }
          }
        });
      } else {
        this.masterService.addVendor(payload).subscribe((res) => {
          if (res) this.doneEvent.emit(payload);
        });
      }
    }
  }

  onCounterPartyNumberChange() {
    const { ctpyTin, category } = this.addMasterCustomerForm.controls;
    const itemsArray = this.getItemsControls();
    const isEI = ctpyTin.value?.startsWith('EI');
    this.isVendor = category.value === 'Vendor';

    if (isEI) {
      this.addMasterCustomerForm.get('ctpyIdNo')?.setValue(this.isVendor ? 'NA' : '');
      itemsArray?.forEach((control) => {
        const itemFormGroup = control as FormGroup;
        itemFormGroup.get('ctpyTtrNo')?.setValue(this.isVendor ? 'NA' : '');
        itemFormGroup.get('ctpySstrNo')?.setValue(this.isVendor ? 'NA' : '');
        itemFormGroup.get('ctpyState')?.setValue(this.isVendor ? '17' : '');
        itemFormGroup.get('ctpyMsic')?.setValue(this.isVendor ? '00000' : '');
        itemFormGroup.get('ctpyActivityDesc')?.setValue('NA');

        this.setControlValidators(
          itemFormGroup.controls.ctpyTtrNo,
          itemFormGroup.get('ctpyTtrNo')?.value,
          this.validationType.ctpyTtrNo,
        );

        this.setControlValidators(
          itemFormGroup.controls.ctpySstrNo,
          itemFormGroup.get('ctpySstrNo')?.value,
          this.validationType.ctpySstrNo,
          'ctpySstrNo',
        );
      });
    } else {
      itemsArray?.forEach((control) => {
        const itemFormGroup = control as FormGroup;
        if (itemFormGroup.get('ctpyTtrNo')) {
          itemFormGroup.get('ctpyTtrNo')?.reset('');
          this.setControlValidators(
            itemFormGroup.controls.ctpyTtrNo,
            itemFormGroup.get('ctpyTtrNo')?.value,
            this.validationType.ctpyTtrNo,
          );
        }
        if (itemFormGroup.get('ctpySstrNo')) {
          itemFormGroup.get('ctpySstrNo')?.reset('');
          this.setControlValidators(
            itemFormGroup.controls.ctpySstrNo,
            itemFormGroup.get('ctpySstrNo')?.value,
            this.validationType.ctpySstrNo,
            'ctpySstrNo',
          );
        }
        if (!this.isVendor) itemFormGroup.get('ctpyActivityDesc')?.setErrors(null);
      });
    }

    this.addMasterCustomerForm.updateValueAndValidity();
    this.cdrf.detectChanges();
  }

  setControlValidators(
    control: AbstractControl,
    value: string,
    validators: ValidatorFn[],
    controlName?: string,
  ) {
    control.setValue(value);
    if ((value === 'NA' || value === '17' || value === '00000') && controlName !== 'ctpySstrNo') {
      console.log('Clearing validators');
      control.clearValidators();
    } else {
      control.setValidators(validators);
    }
    control.updateValueAndValidity();
  }

  validationType = {
    ctpyTtrNo: [noSpacesValidator(), taxNumber],
    ctpySstrNo: [noSpacesValidator(), SSTNumber],
  };

  validateIdentificationNumber(form: FormGroup, idTypeControl: string, idNumberControl: string) {
    const idType = form.get(idTypeControl)?.value;
    const idNumber = form.get(idNumberControl);

    if (idNumber) {
      idNumber.setErrors(null);

      const idNumberValue = idNumber.value;
      if (idNumberValue === 'NA') {
        return;
      }

      if (idType === 'BRN' && idNumberValue?.length > 20) {
        idNumber.setErrors({ maxlength: true });
      } else if (idType === 'Passport' && idNumberValue?.length > 12) {
        idNumber.setErrors({ minlength: true });
      } else if ((idType === 'ARMY' || idType === 'NRIC') && idNumberValue?.length != 12) {
        idNumber.setErrors({ maxlength: true });
      }
    }
  }

  ngOnDestroy(): void {
    this.shippingLists = [];
  }
}
